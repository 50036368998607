.OEEDashboard_mainContainer {
    width: 100%;
    height: 100%;
    /* display: block; */
    /* display: flex; */
    /* text-align: center; */
}

#OEEDashboard_Layout_Div {
    float: left;
    width: calc(100% - 250px);
    height: 100% !important;
    /* margin-top: 45px; */
    /* display: block; */
    /* flex: 1; */
    /* margin-top: -15px; */
    /* display: inline-block;
    vertical-align: middle;
    float: left; */
    /* background-color: ; */
}

.OEEDashboard_mainContainer .dashboardGridLayout {
    height: 100% !important;
}

.OEEDashboard_mainContainer .viewWidget {
    margin: 0;
    display: flex;
    flex-direction: column;
}

.OEEDashboard_mainContainer .widgetSpan {
    top: 10px;
}

.OEEDashboard_mainContainer .widgetTopDiv {
    height: 20px;
}


.OEEDashboard_mainContainer .widgetValueDiv {
    height: calc(100% - 20px);
}

#FilterPanel {
    width: 250px;
    position: fixed;
    right: 5px;
    top: 105px;
}

#FilterPanel {
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
}

#FilterPanel::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
}

.LightTheme .oee_dashboard_widgets {
    background-color: #D1D1D1;
    /* border-radius: 2px; */
    /* box-shadow: 2px 2px 2px #E7E7E7; */
    /* box-shadow: 0px 0px 5px #3C454B */
}

.DarkTheme .oee_dashboard_widgets {
    background-color: #1E2124;
    border-radius: 5px;
    /* box-shadow: 2px 2px 2px #E7E7E7; */
    box-shadow: 0px 0px 5px #3C454B
}

.oee_dashboard_widgets>div {
    width: 100% !important;
    height: 100% !important;
}

.oee_dashboard_widgets .dashboardGridLayout {
    padding-top: 0px !important;
    margin-left: 0px !important;
    /* height: 88% !important; */
}

.oee_dashboard_widgets .breadCrumbsHeader {
    display: none !important;
}

.oee_dashboard_widgets .dashboardLayoutControlsDiv {
    padding-top: 0px !important;
    display: none !important;
}

.oee_dashboard_widgets .LightTheme .viewWidget {
    /* background-color: #E7E7E7 !important; */
}

.oee_dashboard_widgets .DarkTheme .viewWidget {
    /* background-color: #282C30 !important; */
}

.oee_dashboard_widgets .fusioncharts-container {
    /* height: 100% !important; */
}

.oee_dashboard_widgets .widgetTopDiv {
    /* display: none; */
}

.oee_dashboard_widgets .dashboardGridLayoutDiv {
    height: 100% !important;
}

.OEEDashboard_Layout_Div .dashboarChartItem {
    height: 100%!important;
}

.OEEDashboard_Layout_Div div#chartWidget_7,
.OEEDashboard_Layout_Div div#chartWidget_8 {
    height: 100% !important;
    margin-top: 0;
}

.OEEDashboard_Layout_Div .chartDataItem {
    /* position: absolute;
    top: 50%;
    transform: translateY(-46%); */
    height: calc(100% - 32px) !important;
    margin-top: 10px;
}

.oee_dashboard_widgets .widgetValueDiv>div>div {
    height: 100% !important;
}

.oee_dashboard_widgets .RCP>svg {
    width: 100% !important;
    height: 100% !important;

}

.indicator {
    display: flex;
    justify-content: center;
    text-align: center;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    user-select: none;
    align-items: center;
}


.OEEDashboard_calendarDiv {
    /* float: right; */
    /* width: 10%; */
    height: 100%;
    margin-right: 10px;
    background-color: #778187;
}

.oee_dashboard_graphical_container {
    display: flex;
}

.oee_dashboard_graphical_widget {
    flex: 1;
}

.oee_dashboard_widget_info {
    margin-right: 5px;
}

.oee_dashboard_widgets {
    width: 100%;

}

.oee_dashboard_widgets svg {
    width: inherit;
    height: inherit;
}

.oee_dashboard_widgets.kpiMenuBtn {
    font-size: 30px;
    cursor: pointer;
    float: right;
    position: fixed;
    right: 0;
    z-index: 1;
    top: 110px;
    display: block;
}

.OEEDashboard_layoutSidebarContainer {
    width: 100%;
    height: calc(100% - 50px);
    display: inline-block;
}

span.closeKpiButton {
    align-self: flex-end;
}


.OEE_sideNav {
    float: left;
    display: flex;
    height: calc(100% - 105px);
    flex-direction: column;
    padding: 10px;
    box-sizing: border-box;
    row-gap: 10px;
}

.LightTheme .OEE_sideNav {
    background-color: #F1F2F3;
}

.DarkTheme .OEE_sideNav {
    background-color: var(--darktheme-black-variant2);
}


.oee_dashboard_widgets.OEE_sideNav .oee_dashboard_widgets.kpiMenu {
    padding: 10px 8px 10px 64px;
    text-decoration: none;
    font-size: 15px;
    /* font-weight: normal; */
    color: #f1f1f1;
    display: inline-block;
    transition: .1s;
    cursor: pointer;
    vertical-align: middle;

}

.oeeDashboard_inner_menu_bar {
    height: 50px;
    width: 100%;
    display: none;

}

.oeeDashboard_inner_menu_bar {
    background-color: #3C454B;
}

.oeeDashboard_Header_Title {
    height: 50px;
    width: 100%;
    margin-top: 10px;
    font-size: xx-large;
    font-style: normal;
    color: #778187;
    margin-left: 10px;
    display: none;
}

.oee_dashboard_widgets .graphicalView {
    width: 50% !important;
    height: 90%;
    float: left !important;
    background-color: transparent;
}

div#OEEDashboard_Layout_Div {
    /* display: flex;
    flex-direction: column; */
    /* justify-content: center; */
    /* align-items: flex-start; */
    /* column-gap: 10px;
    row-gap: 10px; */
    padding-right: 10px;
    box-sizing: border-box;
}

.oee_dashboardContainer {
    display: flex;
    width: 50%;
    height: 100%;
    column-gap: 10px;

}

.oee_dashboardrow {
    display: flex;
    width: 100%;
    height: 30%;
    column-gap: 10px;
}

div#OEE_Machine {
    min-height: 190px;
}

.oee_dashboard_rowone {
    /* width: 250px; */
    display: flex;
    height: 100%;
    min-height: 190px;
}

div#OEE_partsTotalIndicator {
    width: 35%;
}

div#OEE_partsOverTimeIndicator {
    flex-wrap: wrap;
    width: calc(65% - 10px);
}

.oee_dashboardContainer.oee_containerrows {
    flex-direction: column;
    row-gap: 10px;
    /* height: 100%; */
}

div#OEE_availabilityIndicator {
    height: 50%;
}

div#OEE_downTimeReasonIndicator {
    height: 50%;
}

.OEEDashboard_layoutSidebarContainer .widgetSpan {
    font-size: 16px;
    font-weight: bold;
}

img.oeeDashboardMachineImage {
    width: 10rem;
    /* height: 90%; */
}

.oeeDashboardMachineImageCont {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.oeeDashboardImag {
    display: flex;
    flex-direction: column;
    /* row-gap: 8px; */
}

.oeeDashboardImag img {
    margin-left: 10px
}

span.oeeDashboardMachineName {
    display: flex;
    font-size: 15px;
    font-weight: bold;
    position: absolute;
    top: 10px;
    left: 10px;
}

.oeeDashboardMachineDetails>table>tr {
    line-height: 26px;
}

.oeeDashboardMachineDetails div {
    display: flex;
}

.oeeDashboardMachineDetails .Label {
    width: 190px;
}


.oeeDashboardMachineDetails span {
    width: 145px;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 700;
}

.oeeDashboardMachineDetails .Status {
    color: var(--claret-violet);
}

.oeeDashboardMachineDetails {
    width: 325px;
    overflow-x: hidden;
    font-weight: 700;
}

.overlay {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 3;
    top: 60px;
    left: 0;
    background-color: rgb(60, 69, 75);
    /* background-color: rgba(60, 69, 75, 0.9); */
    overflow-x: hidden;
    transition: 0.5s;
    color: white;
}

.overlay-content {
    position: relative;
    top: 6%;
    right: 0px;
    width: 98%;
    text-align: center;
    margin-top: 30px;
    /* margin-right: 102px; */
    padding: 10px;
}

.overlay span.closebtn {
    padding: 8px;
    text-decoration: none;
    font-size: 36px;
    color: #818181;
    display: block;
    /* transition: 0.3s; */
}

.overlay .closebtn {
    position: absolute;
    top: 20px;
    right: 10px;
    font-size: 60px;
}

.LightTheme .oee_dashboard_widgets {
    background-color: white !important;
}

.oee_dashboard_widgets .LightTheme .viewWidget {
    background-color: white !important;
    overflow: hidden;
}

.OEE_Breadcrumbs {
    position: absolute;
    top: 60px;
    z-index: 2;
    width: 100%;
    /* right: 0px; */
}

.BreadCrumbHeaderHide{
    z-index:0!important
}
[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}

[type="radio"]:checked+label,
[type="radio"]:not(:checked)+label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #000000;
    margin-bottom: 10px;
}

[type="radio"]:checked+label:before,
[type="radio"]:not(:checked)+label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff;
}

[type="radio"]:checked+label:after,
[type="radio"]:not(:checked)+label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: black;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

[type="radio"]:not(:checked)+label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

[type="radio"]:checked+label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.react-grid-layout {
    margin-top: 45px;
    margin-left: -4px;
}

.OEEBottomSpace {
    height: 10px;
}

.LiLayout_settingsPopUp {
    z-index: 3 !important;
}

#LiLayout_appmenu {
    z-index: 3 ;
}

#mobileSidenav {
    z-index: 3 !important;
}

.LiLayout_menuName {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.OEEDashboard_mainContainer .dashboardGridLayoutDiv {
    display: flex;
    justify-content: center;
}

.OEEDashboard_mainContainer #chart_7,
.OEEDashboard_mainContainer #chart_8,
.OEEDashboard_mainContainer #chart_9 {
    position: absolute !important;
    top: -10px;
}

/*
    media query
*/

@media screen and (max-height: 450px) {
    .overlay a {
        font-size: 20px
    }

    .overlay .closebtn {
        font-size: 40px;
        top: 15px;
        right: 35px;
    }
}


@media screen and (max-device-width: 1530px) {
    div#OEEDashboard_Layout_Div {
        padding-right: 0px;
        box-sizing: border-box;
    }
}


@media screen and (max-width: 550px) {
    .oeeDashboardImag {
        margin-right: 10px;
    }
    .oeeDashboardMachineDetails .Label {
        width: 150px;
    }
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 1180px) {
    .OEE_Breadcrumbs {
        position: absolute;
        top: 60px;
        z-index: 2;
        width: 100%;
        /* right: 0px; */
        /* left: 16%; */
    }
}


/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 1200px) {
    #OEEDashboard_Layout_Div #chart_2 text:nth-child(1),
        #OEEDashboard_Layout_Div #chart_3 text:nth-child(1),
        #OEEDashboard_Layout_Div #chart_4 text:nth-child(1),
        #OEEDashboard_Layout_Div #chart_5 text:nth-child(1){
            font-size: 16px !important;
        }
}
@media only screen and (max-width: 1024px) {
    #OEEDashboard_Layout_Div #chart_2 text,
    #OEEDashboard_Layout_Div #chart_3 text,
    #OEEDashboard_Layout_Div #chart_4 text,
    #OEEDashboard_Layout_Div #chart_5 text,
    #OEEDashboard_Layout_Div #chart_6 text,
    #OEEDashboard_Layout_Div #chart_7 text,
    #OEEDashboard_Layout_Div #chart_8 text,
    #OEEDashboard_Layout_Div #chart_9 text,
    #OEEDashboard_Layout_Div #chart_10 text,
    #OEEDashboard_Layout_Div #chart_11 text {
        font-size: 10px !important;
    }

    .oeeDashboardMachineDetails span {
        width: 140px;
        margin-bottom: 10px;
        font-size: 10px;
        font-weight: 700;
    }

    .OEEDashboard_Layout_Div .settingsIcon {
        height: 40px
    }

    .OEE_sideNav {
        display: none !important;
    }

    img.oeeDashboardMachineImage {
        width: 8rem;
    }

    .oee_dashboard_widgets .widgetTopDiv {
        height: 25px;
        width: 94%;
    }

    .oee_dashboard_widgets .widgetValueDiv {
        height: calc(100% - 25px);
    }

    span.oeeDashboardMachineName {
        font-size: 11px;
    }

    .OEEDashboard_layoutSidebarContainer .widgetSpan {
        font-size: 10px;
        font-weight: bold;
        width: 97%;
    }

    .OEEDashboard_Layout_Div {
        width: 100% !important;
        margin-top: 50px !important;
    }

    .kpiMenuBtn {
        display: none;
    }

    .oeeDashboard_inner_menu_bar {
        display: block;
        position: absolute;
        top: 103px;
        z-index: 2;
        right: 0px;
    }

    div#OEEDashboard_Layout_Div {
        width: 100%;
    }

    .oeeDashboard_Header_Title {
        display: block;
    }

    .oee_dashboardrow {
        flex-direction: column;
        width: 100%;
        row-gap: 10px;
    }

    .oee_dashboardContainer {
        width: 100%;
    }

    div#OEEDashboard_Layout_Div {
        display: block;
        padding: 0;
    }

    div#OEE_Machine {
        height: 250px;
    }

    .oee_dashboardrow {
        flex-basis: max-content;
    }


    .oeeDashboard_MenuItems {
        position: absolute;
        top: -40px;
        right: 25px;
        display: flex;
        /* align-self: flex-end; */
        /* width: 100%; */
        justify-content: flex-end;
        align-items: center;
        height: 46px;
        column-gap: 15px;
        padding-right: 10px;
        margin-top: 45px;
    }

    span.oeeDashboard_MenuItems_text img {
        display: flex;
        align-self: center;
        width: 20px;
    }

    .OEE_Breadcrumbs {
        position: absolute;
        top: 60px;
        z-index: 2;
        width: 100%;
        right: 0px;
    }

    .OEE_Breadcrumbs nav {
        margin-left: 20px;
    }

    .overlay-content {
        position: relative;
        top: 6%;
        right: 0px;
        width: 98%;
        text-align: center;
        margin-top: 30px;
        padding-left: 1%;
    }

    div#LiLayout_nav_small{
        line-height: 30px !important;
    }

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1400px) {
    img.oeeDashboardMachineImage {
        width: 8rem;
    }
}
.alarm{
    border-radius: 5px;
    width: 99%;
    text-align: center;
}

.alarm_text{
    width: 100%;
    margin: 5px 0;
}

.information_alarm{
    background-color: white;
    color: black;
    border:1px solid black
}

.warning_alarm{
    background-color: #fff500;
    color: black;
}

.error_alarm{
    background-color: #ff3700;
    color: white;
}

.fatal_alarm{
    background-color: #ff00ff;
    color: white;    
}

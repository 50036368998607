.OEE_FilterShifts {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    row-gap: 15px;
}

.weeknumbercells {
    font-size: 14px;
    color: rgba(125, 125, 125, .87);
}

.MachinesContainer {
    margin-bottom: 30px;
}

.MachinesContainer span {
    /* margin: 10px 0 0 10px; */
}

.MachinesContainerAddALine {
    cursor: pointer;
    float: right;
    font-size: 1rem;
    padding-right: 5px;
}

.MachineGroupContainer {
    display: grid;
    grid-template-columns: 50% 50%;
    margin: 15px 0px;
}

.MachineGroup {
    margin-top: 8px;
    background-color: white;
    cursor: pointer;
    /* width: 225px; */
    /* width: 19.3rem; */
    padding: 12px 40px;
    margin: 5px;
    color: black;
}

.MachineGroupSelected {
    margin-top: 8px;
    background-color: #C4CACE;
    cursor: pointer;
    /* width: 225px; */
    /* padding: 12px 40px; */
    margin: 5px;
}

.MachineSelector_ChooseButton {
    padding: 14px 0px;
    width: 100%;
    background-color: white;
    border: 0.5px;
    border-radius: 2px;
    cursor: pointer;
}

.MachineSelector {
    margin-top: 20px;
}

.MachineSelector_SelectedMachine {
    margin-top: 15px;
}

/* .MachineSelector_SelectedMachineData {
    margin-top: 5px;
    background-color: white;
    /* width: 225px; */
    /* padding: 5px 10px;
    color: black; */
/* } */ 
.MachineSelector_SelectedMachineData {
    margin-top: 5px;
    background-color: white;
    /* width: 225px; */
    padding: 5px 10px 10px;
    background-color: #f1f2f3;
    color: black;
    border-bottom: 2px solid #d0d0d0;
    font-weight: normal;
}
.MachineTile{
    display: flex;
    align-items: center;
    padding: 12px;
    gap: 15px;
    font-weight: normal;
}
.MachineImage{
    width: 50px;
    height: 50px;
    object-fit: contain;
    mix-blend-mode: multiply;
}
.MachineSelector_SelectedMachineData div {}

.MachineSelector_SelectedMachineData span {
    font-size: 0.8rem;
}

.MachineSelector_RecentMachine {
    margin-top: 15px;
}

.MachineSelector_RecentMachineData {
    /* margin-top: 8px;
    background-color: #C4CACE; */
    /* width: 225px; */
    /* padding:5px 10px;
    cursor: pointer; */
    margin-top: 5px;
    background-color: white;
    /* width: 225px; */
    padding: 5px 10px 10px;
    background-color: #f1f2f3;
    color: black;
    border-bottom: 2px solid #d0d0d0;
    font-weight: normal;
}

.MachineSelector_RecentMachineData div {}

.MachineSelector_RecentMachineData span {
    font-size: 0.8rem;
}

.OEEDashboard_autorefresh {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    justify-self: flex-end;
    /* margin: auto; */
    height: 100%;
    align-items: end;
}

.dx-calendar-cell.dx-calendar-selected-date span, .dx-calendar-cell.dx-calendar-selected-date.dx-calendar-today span{
    border-radius: 20% !important;
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dx-calendar-cell span {
    border-radius: 20% !important;
    margin-top: 1px;
}

.dx-calendar-body .weekdaycells{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    line-height: 32px;
}

#FilterPanel{
    display: flex;
    overflow-y: auto;
}

@media screen and (max-width: 1024px) {
    .dx-calendar-cell.dx-calendar-selected-date span, .dx-calendar-cell.dx-calendar-selected-date.dx-calendar-today span{
        border-radius: 20% !important;
        width: 28px;
        height: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
    }
}

#myNav > span:nth-child(2){
    position: absolute;
    top: 42px;
    left: 15px;
}


@media screen and (max-width: 1200px) {
    .MachinesContainer span{
        margin: 0px !important;

    }
    .MachineGropusHeading{
        /* margin-left: 0px !important;
        margin-right: 636px !important; */
    }
    #myNav > div > div:nth-child(1) > div > div > div > span.MachinesContainerAddALine > span{
position: absolute;
    right: 16px;
    top: 10px;
}
#myNav > div > div:nth-child(1) > div > div > div > span:nth-child(1){
    position: absolute;
    left: 15px;
    top: 10px;
    }
    #myNav > div > div:nth-child(1) > div > div > div > span:nth-child(4){
        margin-right: 700px !important;
    }
}

@media screen and (max-width: 768px) {

    #FilterPanel{
        display: none;
    }  

    .OEE_FilterShifts_Mobile {
        display: flex;
        height: 50px;
        background: white;
        color: black;
        align-items: center;
        justify-content: center;
    }

    .OEE_FilterShifts_Mobile.active {
        border: 1px solid var(--darktheme-tufts-blue);
    }

    span.OEE_FilterShifts_Heading {
        display: flex;
        font-size: 23px;
    }

    div#calendar-container>* {
        background: rgb(60, 69, 75);
        color: white !important;
    }

    .dx-calendar-body td {
        color: white !important;
    }

    .OEE_FilterShifts_Mobile {
        margin-top: 8px;
        background-color: white;
        cursor: pointer;
        /* width: 225px; */
        padding: 12px 40px;
        margin: 5px;
        color: black;
    }

    .OEE_FilterShifts_Mobile.active {
        margin-top: 8px;
        background-color: #C4CACE;
        cursor: pointer;
        /* width: 225px; */
        padding: 12px 40px;
        margin: 5px;
        color: black;
    }
}
.MachineStateChanges_Chart{
    height: 200px;
}

.Machine_State_Changes_oee_dashboard_widgets {
    height: 150px;
}

.MachineStageChangesContainer{
    height: 100%;
    margin-top: 35px;
}

.MachineLineManagementContainer {
    margin-top: 10px;
    display: flex;
    padding: 50px;
    background-color: white;
    box-shadow: 5px 5px 3px #aaaaaa;
}

.MachineLineGroupManagementContainer {
    display: grid;
    grid-template-columns: auto auto auto;
    padding: 50px;
    background-color: white;
    box-shadow: 5px 5px 3px #aaaaaa;
    width: calc(100% - 100px);
}

.MachineLineManagementImage{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px;
}

.MachineLineGroupManagementImage{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-right: 20%;
}

.MachineLineManagementImage div{
    margin: 20px;
}

.MachineLineGroupManagementImage div{
    margin-bottom: 20px;
}

.MachineLineManagementImageMain{
    padding: 10px;
    border: 1px solid;
}

.MachineLineManagementFieldsContainer{
    width: 550px;
}

.MachineLineGroupManagementFieldsContainer{
    /* width: 550px; */
}

.MachineLineManagementFields{
    display: grid;
    grid-template-columns: 11vw 350px;
    margin: 15px 0px;
    align-items: center;
}

.MachineLineManagementComboContainer{
    /* display: flex;
    justify-content: space-between; */
    align-items: center;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 25px;
}

#LiCombo_containercalNo{
    width: 100% !important;
}

.MachineLineManagementCombo{
    /* width: 110px; */
    padding-bottom: 10px;
    /* margin-left: 20px; */
    width: 100%;
}

.MachineLineManagementCombo .LiCombo_main{
    width: 100%;
}

.MachineLineMachinesContainerMain{
    display: flex;
    /* justify-content: center; */
    align-items: flex-start;
}

.MachineLineMachinesContainer{
    display: flex;
    justify-content: center;
    flex-direction: column;
    /* margin-left: 33%; */
}

.MachineLineMachines{
    width: 220px;
    display: flex;
    justify-content: space-between;
    align-items:center;
    /* margin-left: 90%; */
    margin-top: 0px;
    border: 1px solid;
    padding: 5px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    gap: 5px;
}

.MachineLineMachines div{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.MachineLineMachines span {
    margin: 2px;
    width: 130px;
}

.MachineLineMachines img {
    margin-right: 7px;
}

.MachineLineMachinesButton button{
    position: relative;
    border: 0px solid;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    right: calc(50% - 18px);
    /* margin-left: -5%;
    margin-top: 55px; */
    background-color: var(--darktheme-tufts-blue);
    color: white;
    cursor: pointer;
    font-size: 22px;
}

.MachineLineMachines:hover .MachineLineMachinesDeleteButton button {
    display: block;
}
    

/* .MachineLineMachinesDeleteButton button {
    display: block;
} */

.PleaseSelectAMachineMessage{
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.MachineLineMachinesDeleteButton button {
    /* position: absolute; */
    display: none;
    border: 0px solid;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin-right: 5px;
    /* margin-top: 55px; */
    background-color: red;
    color: white;
    cursor: pointer;
    font-size: 16px;
}

.MachineLineMachinesButtonAlone{
    position: absolute;
    /* margin-top: 70px; */
    margin-left: 25px;
    /* margin-left: 5%; */
}

.MachineLineMachinesButtonAlone button{
    position: absolute;
    border: 0px solid;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    /* margin-right: 10%; */
    margin-left: -50%;
    margin-top: 30px;
    background-color: var(--darktheme-tufts-blue);
    color: white;
    cursor: pointer;
    font-size: 22px;
}

.MachineLineMachinesButtonLine{
    position: relative;
    /* margin-left: -4.4%; */
    /* margin-top: 30px; */
    /* margin-left: 79%; */
    left: 50%;
    height: 25px;
    width: 1px !important;
    background-color: black;
}

.MachineLineMachinesButtonLineEnd{
    position: relative;
    /* margin-left: -4.4%; */
    margin-top: 24px;
    /* margin-left: 79%; */
    left: 50%;
    height: 25px;
    width: 1px !important;
    background-color: black;
}

.MachineStageChangesContainerSubmitButton button{
    float: right;
}

.MachineStageChangesContainerSubmitButton{
    display: flex;
    width: 100%;
    justify-content: flex-end;
}

.MachineLineManagementImage input {
    background-color: white;
}

#input[type="file"] {
	display: none;
}
.label{
  width: 100%;
  display: flex;
  justify-content: center;
}
.image-upload{
  margin: auto;
  padding-top: 10px;
  width: 150px;
  height: 30px;
  color: var(--darktheme-tufts-blue);
  border: 1px var(--darktheme-tufts-blue) solid;
  border-radius: 10px;
  background-color: white;
  text-align: center;
  cursor: pointer;
  margin-bottom: 20px;
}

#machine > div > div.dx-treelist-rowsview.dx-treelist-nowrap.dx-scrollable.dx-visibility-change-handler.dx-scrollable-both.dx-scrollable-simulated.dx-last-row-border > div > div > div.dx-scrollable-content > div > table > tbody > tr > td.dx-treelist-cell-expandable > div.dx-treelist-icon-container.dx-cell-focus-disabled.dx-editor-inline-block > div.dx-select-checkbox.dx-treelist-checkbox-size.dx-show-invalid-badge.dx-checkbox.dx-widget  {
    display: none;
}

.dx-select-checkbox .dx-checkbox-container{
    display: none;
}


#machine > div > div.dx-treelist-header-panel > div > div{
    display: none;
}

.OEEDashboard_Layout_Div button {
    float: right;
    margin-right: 5px;
}

.MachineLineManagementFields .LiLineEdit{
    width: 97.9%;
}

.MachineLineManagementFieldsLabel {
    /* display: flex; */
    align-items: center;
}

.MachineLineManagementFieldsCombo{
    margin-bottom: 5px;

}
.MachineLineManagementFields .MachineLineManagementFieldsCombo{
    width: 99.7%;
}
.MachineLineManagementComboContainer input {
    margin-right: 5px;
}

.AlarmCategorySpace{
    height: 50px;
}

/* .MachineStageChangesContainer tr {
    
}

#summary_data > div:nth-child(1) > div > table > tbody > tr{
    background-color: white;
    height: 36px !important;
    border-bottom: 1px solid black !important;
} */

#summary_data > div > div > table > tbody > tr{
    background-color: white !important;
    height: 40px !important;
}

.LiUserTable_rowColumn{
    border-bottom: 1px solid #E2E5E6 !important;
    background-color: white !important;
}

.LiTable_active{
}
#summary_data > div:nth-child(1) > div > table > tbody > tr.LightTheme.LiUserTable_row.maintableRow.LiTable_active > td{
    background-color:var(--darktheme-tufts-blue) !important;

}

#summary_data > div > div > table > tbody > tr > td{
    background-color: white !important;
}

#summary_data > div > div > table > thead > tr.LiTableRowBackGround > th.LightTheme.LiUserTable_headerColumn.actionColumn{
    background-color : #E2E5E6 !important
}

#summary_data > div:nth-child(1) > div > table > thead > tr.LiTableRowBackGround > th{
    background-color : #E2E5E6 !important
}

.MachineStageChangesTableContainer{
    margin-top: 5px;
    padding: 4px;
    background-color: white;
}

#downtimeThresholdDisabled, #areaThresholdDisabled, #weightThresholdDisabled, #partThresholdDisabled{
    color: #778187 !important;
    
}

.LiComboBox_disabled .LiCombo_selected{
    color: #778187 !important;
}

@media screen and (max-width: 1190px) {
    .MachineGroup {
        margin-top: 8px;
        background-color: white;
        cursor: pointer;
        /* width: 21.5rem; */
        padding: 12px 40px;
        margin: 5px;
        color: black;
    }
    
    .MachineGroupSelected {
        margin-top: 8px;
        background-color: #C4CACE;
        cursor: pointer;
        /* width: 19.3rem; */
        padding: 12px 40px;
        margin: 5px;
    }
}

@media screen and (max-width: 830px) {
    .MachineGroup {
        margin-top: 8px;
        background-color: white;
        cursor: pointer;
        /* width: 225px; */
        width: 19.3rem;
        padding: 12px 40px;
        margin: 5px;
        color: black;
    }
    
    .MachineGroupSelected {
        margin-top: 8px;
        background-color: #C4CACE;
        cursor: pointer;
        width: 19.5rem;
        /* width: 225px; */
        padding: 12px 40px;
        margin: 5px;
    }
}

@media screen and (max-width: 600px) {
    .MachineGroupContainer {
        grid-template-columns: auto;
    }
}

@media screen and (max-width: 768px) {

    
    .MachineGroup {
        color: black;
        width: auto;
    }

    .MachineGroupSelected {
        width: auto;
    }
    #myNav > div > div:nth-child(1) > div > div > div > span.MachinesContainerAddALine > span {
        position: absolute;
        right: 17px;
        top: 10px;
    }
    #myNav > div > div:nth-child(1) > div > div > div > span:nth-child(4) {
        margin-right: 78% !important;
    }
}

@media screen and (max-width: 1300px) {
    .MachineLineManagementContainer {
        display: block;
        padding: 50px;
        background-color: white;
        box-shadow: 5px 5px 3px #aaaaaa;
    }
}

.MachineLineClick{
    color: blue;
    text-decoration: underline;
    cursor: pointer;
}

.MandatoryStar {
    color: red;
    margin: 2px;
}

@media screen and (max-width: 1677px) {
    .MachineLineGroupManagementContainer {
        display: block;
        padding: 50px;
        background-color: white;
        box-shadow: 5px 5px 3px #aaaaaa;
    }
    .MachineLineMachinesButtonLine{
        position: relative;
        margin: 0;
        left: 50%;
    }
    
    .MachineLineMachinesButtonLineEnd{
        position: relative;
        margin-top: 24px;
        left: 50%;
    }
    .MachineLineMachinesButton button{
        position: relative;
        margin: 0;
        right: 44.5%;
    }
    
    .MachineLineMachines{
        width: 220px;
        display: flex;
        justify-content: space-between;
        align-items:center;
        /* margin-left: 90%; */
        margin-top: 0px;
        border: 1px solid;
        padding: 5px;
        margin-bottom: 0px;
        gap: 5px;
    }

    .MachineLineMachinesButtonAlone{
        position: relative;
        margin-top: 20px;
        margin-left: 15vh;
    }
    
    .MachineLineMachinesButtonAlone button{
        position: relative;
        border: 0px solid;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        margin-right: 50%;
        margin-left: 0;
        margin-top: 10px;
        background-color: var(--darktheme-tufts-blue);
        color: white;
        cursor: pointer;
        font-size: 22px;
    }

}

@media screen and (max-width: 1465px) {
    .MachineLineManagementFields{
        grid-template-columns: 150px 300px;
        align-items: center;
    }
}

@media screen and (max-width: 1385px) {
    .MachineLineManagementFields{
        grid-template-columns: 150px 200px;
        align-items: center;
    }
}

@media screen and (max-width: 1300px) {
    .MachineLineManagementFields{
        grid-template-columns: 150px auto;
        align-items: center;
    }
}

@media screen and (max-width: 1062px) {
    .MachineLineManagementFieldsContainer{
        width: 500px;
    }
    .MachineLineManagementFields{
        grid-template-columns: 150px auto;
        align-items: center;
    }
}

@media screen and (max-width: 573px) {
    .MachineLineManagementFieldsContainer{
        width: 350px;
    }
    .MachineLineManagementFields{
        grid-template-columns: 150px auto;
        align-items: center;
    }
}

@media screen and (max-width: 427px) {
    .MachineLineManagementFieldsContainer{
        width: 275px;
    }
}
